export default {
  "show-borders": true,
  "show-column-lines": true,
  "show-row-lines": true,
  "remote-operations": true,
  hoverStateEnabled: true,

  DxPager: {
    "show-info": true,
  },
};

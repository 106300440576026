import { createStore } from "devextreme-aspnet-data-nojquery";
import { userService } from "../authservice/user.service";
import { authHeader } from "@/helpers/authservice/auth-header";

function GetErrors(data) {
  if (data.responseText) {
    var ex = JSON.parse(data.responseText);
    var keys = Object.keys(ex.errors);
    var errorDiv = "<ul class='m-0'>";
    for (var i = 0; i < keys.length; i++) {
      for (var j = 0; j < ex.errors[keys[i]].length; j++) {
        errorDiv += "<li>" + ex.errors[keys[i]][j] + "</li>";
      }
    }
    errorDiv += "</ul>";
    return errorDiv;
  }
  return null;
}

export default function createStoreExtend(options) {
  var currentBeforeSend = options.onBeforeSend;
  options.onBeforeSend = function(method, ajaxOptions) {
    ajaxOptions.xhrFields = { withCredentials: true };
    ajaxOptions.headers = authHeader();

    if (currentBeforeSend) {
      currentBeforeSend(method, ajaxOptions);
    }
  };

  var currentAjaxError = options.onAjaxError;
  options.onAjaxError = function(response) {

    var errors = GetErrors(response.xhr);
    if (errors) {
      setTimeout(() => {
        var errorRow = document.querySelector('.dx-error-message');
        errorRow.innerHTML = errors;
      });         // errorRow.innerHTML = "Hello wrold";
    }

    if (response.xhr.status === 401) {
      // auto logout if 401 response returned from api
      userService.logout();
      location.reload(true);
      //   const error = (data && data.message) || response.statusText;
      //   return Promise.reject(error);
      return false;
    }

    if (currentAjaxError) {
      currentAjaxError(response);
    }
  };

  return createStore({ ...options });
}

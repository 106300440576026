<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxDataGrid id="gvCourts" ref="gvCourts" v-bind="options" :data-source="courtsStore">
              <DxColumn data-field="number" caption="Судебный участок" />
              <DxColumn data-field="address" caption="Адрес судебного участка" />
              <DxColumn data-field="recipientName" caption="Наименование получателя платежа" />
              <DxColumn data-field="recipientAccount" caption="Счет получателя платежа" />
              <DxColumn data-field="recipientINN" caption="ИНН получателя" />
              <DxColumn data-field="recipientKPK" caption="КПП получателя" />
              <DxColumn data-field="recipientBank" caption="Банк получателя" />
              <DxColumn data-field="recipientBankCity" caption="Город банка получателя" width="100px" />
              <DxColumn data-field="recipientBankBIK" caption="БИК банка получателя" />
              <DxColumn data-field="treasuryAccount" caption="Казначейский счет" />
              <DxColumn data-field="kbk" caption="КБК" />
              <DxColumn data-field="oktmo" caption="ОКТМО" />

              <DxColumn
                :visible="false"
                edit-cell-template="editPreparationCourtOrderTemplates"
                data-field="preparationCourtOrderTemplatesIds"
              >
                <DxFormItem :col-span="2">
                  <DxLabel location="top" text="Шаблон заявления о вынесении судебного приказа" />
                </DxFormItem>
              </DxColumn>

              <DxColumn :visible="false" edit-cell-template="editPreparationWritTemplates" data-field="preparationWritTemplatesIds">
                <DxFormItem :col-span="2">
                  <DxLabel location="top" text="Шаблон искового заявления" />
                </DxFormItem>
              </DxColumn>

              <!-- templates -->
              <template #editPreparationCourtOrderTemplates="{ data: rowInfo }">
                <DxTagBox
                  :data-source="templatesStore"
                  :show-selection-controls="true"
                  :value="rowInfo.data.preparationCourtOrderTemplatesIds"
                  :search-enabled="true"
                  @value-changed="(e) => onTemplatesChanged(e, rowInfo)"
                  display-expr="name"
                  value-expr="fileId"
                />
              </template>
              <template #editPreparationWritTemplates="{ data: rowInfo }">
                <DxTagBox
                  :data-source="templatesStore"
                  :show-selection-controls="true"
                  :value="rowInfo.data.preparationWritTemplatesIds"
                  :search-enabled="true"
                  @value-changed="(e) => onTemplatesChanged(e, rowInfo)"
                  display-expr="name"
                  value-expr="fileId"
                />
              </template>

              <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="form">
                <DxPopup height="auto" width="800" />
              </DxEditing>
              <DxFilterRow :visible="true" />
              <DxScrolling row-rendering-mode="virtual" />
              <DxPaging :page-size="20" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 20, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { DxFormItem, DxLabel } from "devextreme-vue/data-grid";

import DxTagBox from "devextreme-vue/tag-box";
import { DxPopup } from "devextreme-vue/data-grid";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxTagBox,
    DxFormItem,
    DxLabel,
  },

  data() {
    return {
      title: "Судебные участки",
      items: [
        {
          text: "Справочник",
          href: "/",
        },
        {
          text: "Судебные участки",
          active: true,
        },
      ],

      options: settings,
      courtsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_COURTS_GET}`,
        insertUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_COURTS_CREATE}`,
        updateUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_COURTS_UPDATE}`,
        deleteUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_COURTS_DELETE}`,
      }),
      templatesStore: createStoreExtend({
        key: "fileId",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_GET}`,
      }),
    };
  },

  methods: {
    onTemplatesChanged(e, data) {
      data.setValue(e.value);
    },
  },
};
</script>

<style></style>
